import { getUserIdentifier, getUserExpress, token_name } from '../../helpers/session';
import { BASE_API } from "../../helpers/base_api";
import store from "../store";
import { setCustomerId as setCustomerIdRTK, setAddressId as setAddressIdRTK } from '../slices/userSlice';
import localStorageConstants from '../../config/localStorageConstants';
import config_src from '../../config/config.js';

const {customer_id, address_id, excludeVAT: excludeVatLS, isCompany: isCompanyLS} = localStorageConstants;

const locale = config_src.locale;
const default_country_id = config_src.countryDefault.id;
//TODO avoid double initialisation for config

export function deleteSessionCart() {
	localStorage.removeItem('cart_id');
	localStorage.removeItem('delivery_id');
}

export function setSessionDeliveryId(deliveryId) {
	localStorage.setItem('delivery_id', Number(deliveryId));
}

export function getSessionDeliveryId() {
	return Number(localStorage.getItem('delivery_id'));
}

export function checkSessionDeliveryId() {
	let deliveryId = getSessionDeliveryId();
	if (deliveryId === 0) {
		deliveryId = 77;
		getDeliveryMethods().then((data) => {
			if (data && data.length > 0) {
				const positions = data.map(item => item.position);
				const smallest = Math.min(...positions);
				const itemWithSmallestPosition = data.find(item => item.position === smallest);
				if (itemWithSmallestPosition) {
					deliveryId = itemWithSmallestPosition.id;
					setSessionDeliveryId(deliveryId);
				}
			}
		});
		setSessionDeliveryId(deliveryId);
	} else {
		getDeliveryMethods().then((data) => {
			if (data && data.length > 0) {
				const foundDelivery = data.find(item => item.id === deliveryId);
				if (!foundDelivery) {
					const positions = data.map(item => item.position);
					const smallest = Math.min(...positions);
					const itemWithSmallestPosition = data.find(item => item.position === smallest);
					if (itemWithSmallestPosition) {
						deliveryId = itemWithSmallestPosition.id;
						setSessionDeliveryId(deliveryId);
					}
				}
			}
		});
	}
	return deliveryId;
}

export function setSessionCartId(cartId) {
	localStorage.setItem('cart_id', Number(cartId));
}

export function getSessionCartId() {
	let cartId = Number(localStorage.getItem('cart_id'));
	// console.log('LocalStorage cartId', cartId);
	// console.log("Redux cartId", getReduxSessionCartId());

	if(cartId === 0 && getReduxSessionCartId() !== 0){
		cartId = getReduxSessionCartId();
	}
	// console.log({ cartId });
	return cartId;
}

export function getReduxSessionCartId() {
	const reduxLocalStorageSimple = JSON.parse(localStorage.getItem('redux_localstorage_simple'));
	if (reduxLocalStorageSimple && reduxLocalStorageSimple.cartData && reduxLocalStorageSimple.cartData.length > 0) {
		const cartId = reduxLocalStorageSimple.cartData[0].cartId;

		if (!isNaN(cartId)) {
			return Number(cartId);
		}
	}
	// console.log("Going to set it to 0");
	return 0;
}

export function getSessionCartItemId() {
	const reduxLocalStorageSimple = JSON.parse(localStorage.getItem('redux_localstorage_simple'));
	return Number(reduxLocalStorageSimple.cartData[0].cartItemId);
}

export function getSessionCartItemQuantity() {
	const reduxLocalStorageSimple = JSON.parse(localStorage.getItem('redux_localstorage_simple'));
	return Number(reduxLocalStorageSimple.cartData[0].quantity);
}

function cartAddConverter(data) {
	let payload;
	if (typeof getSessionCartId() == 'number') {
		payload = {
			'thelia.cart_id': getSessionCartId(),
			'thelia_cart_add': {
				'append': 1,
				'product': data.item.id,
				'product_sale_elements_id': data.item.PRODUCT_SALE_ELEMENT,
				'quantity': data.quantity ? data.quantity : 1,
			},
			'thelia_login': data.thelia_login,
		};
	} else {
		payload = {
			'thelia_cart_add': {
				'append': 1,
				'product': data.item.id,
				'product_sale_elements_id': data.item.PRODUCT_SALE_ELEMENT,
				'quantity': data.quantity ? data.quantity : 1,
			},
			'thelia_login': data.thelia_login,
		};
	}
	return payload;
}

export function updateProductInCart(data) {
	let payload;
	if (typeof getSessionCartId() == 'number') {
		let currentQuantity = data.item.quantity;
		let desiredQuantity = data.quantityCount;
		payload = {
			'thelia.cart_id': getSessionCartId(),
			'cart_item': data.item.cartItemId,
			'quantity': data.action === 'decrease' ? currentQuantity - desiredQuantity : currentQuantity + desiredQuantity,
			'thelia_login': data.thelia_login,
		};
	}
	// console.log({ payload });
	return payload;
}

function cartRemoveConverter(data) {
	let payload = {
		'thelia.cart_id': data.item.cartId,
		'cart_item': data.item.cartItemId,
	};
	return payload;
}

function cartClearConverter(data) {
	let payload = {
		'thelia.cart_id': getSessionCartId(),
		'thelia_loign': data,
	};
	return payload;
}

export function removeCart() {
	localStorage.removeItem('cart_id');
	localStorage.removeItem('delivery_id');
}

export function setAddressId(addressId) {
  store.dispatch(setAddressIdRTK(Number(addressId)));
	localStorage.setItem(addressId, Number(addressId));
}

export function setCustomerId(customerId) {
	store.dispatch(setCustomerIdRTK(Number(customerId)));
  localStorage.setItem(customer_id, customerId);
}

export const getExcludeVat = () => {
  const state = store.getState();
  let excludeVAT = state.user.excludeVAT;
  let localStorageVAT = localStorage.getItem(excludeVatLS);
  return excludeVAT || localStorageVAT;
}

export function getCustomerId() {
	const state = store.getState();
  const customerId = state.user.customer_id;
  const localCustomerId = localStorage.getItem(customer_id);
	return Number(customerId) || Number(localCustomerId);
}

export function getAppliedCoupon() {
	return localStorage.getItem('appliedCoupon');

}

export function getAddressId() {
  const state = store.getState();
  let addressId = state.user.address_id;
  let localAddressID = localStorage.getItem(address_id);
  return addressId || localAddressID;
}

export function getCustomerCompany() {
	const state = store.getState();
	const isCompany = state?.user?.isCompany;
	const localStorageCompany = localStorage.getItem(isCompanyLS);
	const both = isCompany || localStorageCompany;
	return both === "true" || isCompany;
}

export function checkCountryMatchDefault() {
	// check if customer country is the same as default country
	const state = store.getState();
	let customer_country_id = state.user.country_id;
	
	if(default_country_id == customer_country_id) 
		return true;
	else
		return false;
}

function cartPay(paymentMethod,deliveryModuleId, deliveryAddressId, billingAddressId, message, isCompleteDelivery, guest, countryId, isCompany, vatFree) {
    let exclVat = getExcludeVat();
    if(guest === 1 && vatFree === true) {
        exclVat = 'true';
    }
    let payload = {
        "cart_id": getSessionCartId(),
		    "customer_id":getCustomerId() || getUserExpress(),
	      "appliedCoupon": getAppliedCoupon(),
        "api_call": "TRUE",
        "session_id": 4324,
        "email": getUserIdentifier(),
        "delivery_address_id": deliveryAddressId,
        "delivery_module_id": deliveryModuleId,
        "invoice_address_id": billingAddressId,
        "payment_module_id": paymentMethod,
        "postage_tax_rule_title": "SBSH Sofortbad",
        "country_id": countryId,
        "locale": locale,
        "isCompany": exclVat === 'true' && isCompany ? exclVat : false,
        "message": message,
        "isCompleteDelivery": isCompleteDelivery,
        "guest": guest
    }
    return payload;
}

export function getDeliveryCosts(delivery_module_id, countryId) {
    if (typeof getSessionCartId() == "number") {
        let payload = {
            "country_id": countryId,
            "state_id": null,
            "cart_id": getSessionCartId(),
            "locale": locale,
					  "delivery_module_id": delivery_module_id,
        }
        return payload;
    }
    return false;
}

export function placeOrder(paymentMethod,deliveryModuleId,deliveryAddressId,billingAddressId,message,isCompleteDelivery,guest,countryId,isCompany,vatFree) {
	return API.pay(paymentMethod,deliveryModuleId,deliveryAddressId,billingAddressId,message,isCompleteDelivery,guest,countryId,isCompany,vatFree)
		.then(response => {
			// const responseObject = JSON.parse(response);
			const paymentURL = response;
			if (paymentURL) {
				localStorage.removeItem("appliedCoupon");
				localStorage.removeItem("appliedCouponDiscount");
				if(paymentMethod.code !== "StripePayment" || !paymentURL.startsWith("sessionId___")) {
					window.location.assign(paymentURL);
				} else {
					return paymentURL;
				}
				return true; // return true on success
			} else {
				return false; // return false if paymentURL is not obtained
			}
		})
		.catch(error => {
			console.log(error);
			return false;
		});
}

export function saveLocalPickup(cartLocalPickup) {
	return API.orderLocalPickup(cartLocalPickup)
		.then(response => {
			if (response === 'Order Local Pickup Updated') {
				return true;
			} else {
				return false;
			}
		})
		.catch(error => {
			console.log(error);
			return false;
		});
}

export function saveLocalPickupAddress(localPickupAddress) {
	return API.orderLocalPickupAddress(localPickupAddress)
		.then(response => {
			if (response === 'Order Local Pickup Address Updated') {
				return true;
			} else {
				return false;
			}
		})
		.catch(error => {
			console.log(error);
			return false;
		});
}

export function getPlacedOrder(order_id) {
	return API.getPlacedOrder(order_id).then((data) => {
		return data?.OrderItems[0];
	}).catch((error) => {
		return false;
	});
}

export function getOrderProducts(order_id) {
    return API.getOrderProducts(order_id).then((data) => {
        return data['OrderProductItems'];
    }).catch((error) => {
        return false
    });
}

export function getConfig(config_variable) {
	return API.getConfigs(config_variable).then((data) => {
		return data;
	}).catch((error) => {
		console.log(error);
		return false;
	});
}

export function getPaymentMethods() {
	return API.fetchPaymentMethods().then((data) => {
		return data['data'];
	}).catch((error) => {
		console.log(error);
		return false;
	});
}

export function getDeliveryMethods() {
	return API.fetchDeliveryMethods().then((data) => {
		return data['data'];
	}).catch((error) => {
		console.log(error);
		return false;
	});
}

export function assignCartCustomer(email, cart_id) {
	const existingCart = JSON.parse(localStorage.getItem('redux_localstorage_simple')).cartData;
	if (Object.keys(existingCart).length > 0) {
		return API.assignCartCustomerApi({ email: email, cart_id: cart_id }).then((data) => {
			return data;
		}).catch((error) => {
			console.log(error);
			return false;
		});

	}
}

export function handlePayUrl(paymentMethod){
    if(paymentMethod === "PayPal"){
        return API.paypalPayCartUrl;
    }else if(paymentMethod === "GlobalPayments"){
        return API.gpPayCartUrl;
    }else if(paymentMethod === "Sofort"){
		return API.sofortPayCartUrl;
	}else if(paymentMethod === "StripePayment"){
		return API.stripePayCartUrl;
	}else if (paymentMethod === "Nachnahme"){
    	return API.rambursPayCartUrl;
	}else if (paymentMethod === "Vorkasse"){
    	return API.vorkassePayCartUrl;
	}
}

export function getProduct(productId) {
    return API.getProduct(productId).then((data) => {
        return data[0];
    }).catch((error) => {
        return false
    });
}


export const API = {
	cartAddUrl: '/cart/add',
	cartUpdateUrl: '/cart/update',
	checkCartUrl: '/cart/get',
	cartDeleteUrl: '/cart/delete',
	cartClearUrl: '/cart/clear',
	paypalPayCartUrl: '/paypal/pay',
	amazonPayUrl: '/amazon/pay',
	gpPayCartUrl: '/globalpayments/pay',
	sofortPayCartUrl: '/sofort/pay',
	stripePayCartUrl: '/stripe/pay',
	rambursPayCartUrl: '/ramburs/pay',
	vorkassePayCartUrl: '/vorkasse/pay',
	orderLocalPickupUrl: '/localpickup/order',
	orderLocalPickupAddressUrl: '/localPickupAddress/order',
	deliveryCostsUrl: '/customdelivery/postage',
	getOrderUrl: '/ExternalOrderApi/order',
	getConfigUrl: '/api/system_config/custom_attribute/?variable_name=',
	assignCartCustomerUrl: '/cart/assignCustomer',
	fetchPaymentMethodsUrl: '/ExternalOrderApi/getActivePaymentMethods',
	fetchDeliveryMethodsUrl: '/ExternalOrderApi/getActiveDelivery',
	productUrl: '/products',
	orderProductsUlr: '/ExternalOrderApi/order_product',
	getQrCodeUrl: '/ExternalOrderApi/getQrCode',
	cartAbandonedUrl: '/cart/abandoned',
	addToCart: data =>
		BASE_API.makeRequest(
			`${BASE_API.theliaApi}/api${API.cartAddUrl}`,
			'POST',
			cartAddConverter(data),
			localStorage.getItem(token_name) ? 'Bearer' : 'Thelia',
		),
	removeFromCart: data =>
		BASE_API.makeRequest(
			`${BASE_API.theliaApi}/api${API.cartDeleteUrl}`,
			'POST',
			cartRemoveConverter(data),
			localStorage.getItem(token_name) ? 'Bearer' : 'Thelia',
		),

	clearCart: thelia_login =>
		BASE_API.makeRequest(
			`${BASE_API.theliaApi}/api${API.cartClearUrl}`,
			'POST',
			cartClearConverter(thelia_login),
			localStorage.getItem(token_name) ? 'Bearer' : 'Thelia',
		),
	checkCart: (cart_id, thelia_login) =>
		BASE_API.makeRequest(
			`${BASE_API.theliaApi}/api${API.checkCartUrl}`,
			'POST',
			{ 'thelia.cart_id': cart_id, thelia_login: thelia_login },
			localStorage.getItem(token_name) ? 'Bearer' : 'Thelia',
		),
	updateQuantity: data =>
		BASE_API.makeRequest(
			`${BASE_API.theliaApi}/api${API.cartUpdateUrl}`,
			'POST',
			updateProductInCart(data),
			localStorage.getItem(token_name) ? 'Bearer' : 'Thelia',
		),

	pay: (paymentMethod,deliveryModuleId,deliveryAddressId,billingAddressId,message,isCompleteDelivery,guest, countryId,isCompany,vatFree) =>
		BASE_API.makeRequest(
			`${BASE_API.theliaApi}/api${handlePayUrl(paymentMethod.code)}`,
			'POST',
			cartPay(paymentMethod.id,deliveryModuleId,deliveryAddressId,billingAddressId,message,isCompleteDelivery,guest, countryId,isCompany,vatFree),
			localStorage.getItem(token_name) ? 'Bearer' : 'Thelia',
		),
	amazonPay: (cartId,amazonPaySessionId,customer_note) =>
		BASE_API.makeRequest(
			`${BASE_API.theliaApi}/api${API.amazonPayUrl}`,
			'POST',
			{cart_id: cartId, amazon_session_id: amazonPaySessionId, customer_note: customer_note},
			localStorage.getItem(token_name) ? 'Bearer' : 'Thelia',
		),
	orderLocalPickup: (cartLocalPickup) =>
		BASE_API.makeRequest(
			`${BASE_API.theliaApi}/api${API.orderLocalPickupUrl}`,
			'POST',
			cartLocalPickup,
			localStorage.getItem(token_name) ? 'Bearer' : 'Thelia',
		),
	orderLocalPickupAddress: (localPickupAddress) =>
		BASE_API.makeRequest(
			`${BASE_API.theliaApi}/api${API.orderLocalPickupAddressUrl}`,
			'POST',
			localPickupAddress,
			localStorage.getItem(token_name) ? 'Bearer' : 'Thelia',
		),
	getDeliveryCosts: (delivery_module_id, countryId) =>
		BASE_API.makeRequest(
			`${BASE_API.theliaApi}/api${API.deliveryCostsUrl}`,
			'POST',
			getDeliveryCosts(delivery_module_id,countryId),
			localStorage.getItem(token_name) ? 'Bearer' : 'Thelia',
		),
	getPlacedOrder: order_id => BASE_API.makeRequest(
		`${BASE_API.theliaApi}/api${API.getOrderUrl}/${order_id}`,
		'GET',
		{},
		localStorage.getItem(token_name) ? 'Bearer' : 'Thelia'),
	getConfigs: data => BASE_API.makeRequest(`${BASE_API.fastApi}${API.getConfigUrl}${data}`),
	fetchPaymentMethods: () =>
		BASE_API.makeRequest(`${BASE_API.theliaApi}/api${API.fetchPaymentMethodsUrl}/${localStorage.getItem('cart_id')}`,
		'GET',
		{},
		localStorage.getItem(token_name) ? 'Bearer' : 'Thelia'),
	fetchDeliveryMethods: () =>
		BASE_API.makeRequest(
			`${BASE_API.theliaApi}/api${API.fetchDeliveryMethodsUrl}/${localStorage.getItem('cart_id')}`,
			'GET',
			{},
			localStorage.getItem(token_name) ? 'Bearer' : 'Thelia'),
	assignCartCustomerApi: (data) =>
		BASE_API.makeRequest(
			`${BASE_API.theliaApi}/api${API.assignCartCustomerUrl}`,
			'POST',
			data,
			localStorage.getItem(token_name) ? 'Bearer' : 'Thelia'),
	getProduct:(product_id) => BASE_API.makeRequest(
		`${BASE_API.theliaApi}/api${API.productUrl}/${product_id}`,
		'GET',
		{},
		localStorage.getItem(token_name) ? 'Bearer' : 'Thelia'),
	getOrderProducts: (order_id) => BASE_API.makeRequest(
		`${BASE_API.theliaApi}/api${API.orderProductsUlr}/?order_id=${order_id}`,
		'GET',
		{},
		localStorage.getItem(token_name) ? 'Bearer' : 'Thelia'),
	getQrImage: (data) =>
		BASE_API.makeRequest(
			`${BASE_API.theliaApi}/api${API.getQrCodeUrl}`,
			'POST',
			data,
			localStorage.getItem(token_name) ? 'Bearer' : 'Thelia',
		),
	abandonedCart: (data) =>
		BASE_API.makeRequest(
			`${BASE_API.theliaApi}/api${API.cartAbandonedUrl}`,
			'POST',
			data,
			localStorage.getItem(token_name) ? 'Bearer' : 'Thelia',
		),
};
