import { API, deleteSessionCart, getSessionCartId, removeCart, setSessionCartId } from '../api/cart.js';
import { getUserIdentifier, token_name } from '../../helpers/session.js';
import { pushDataLayer } from '../../helpers/gtmDataLayer.js';

export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";
export const CHANGE_FULFILMENT = "CHANGE_FULFILMENT";
export const SET_CART_ID = "SET_CART_ID";
export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM";

// add set to cart
export const addSetToCart = (
    items,
    addToast,
    quantityCount,
    selectedProductColor,
    selectedProductSize,
    translatedMessage
) => async dispatch => {
    let allSuccess = true;
    for (const item of items) {
        const result = await dispatch(addToCart(
            item,
            null,
            quantityCount,
            selectedProductColor,
            selectedProductSize,
            translatedMessage
        ));
        if (!result) {
            allSuccess = false;
        }
    }

    if (allSuccess) {
        if (addToast) {
            addToast(translatedMessage, { appearance: "success", autoDismiss: true }); // Added To Cart
        }
    } else if (addToast) {
        addToast("Hinzufügen zum Einkaufswagen fehlgeschlagen", {
            appearance: "error",
            autoDismiss: true
        }); // Adding to cart failed
    }

};

// add to cart
export const addToCart = (
    item,
    addToast,
    quantityCount,
    selectedProductColor,
    selectedProductSize,
    translatedMessage,
    selectedFulfilmentCenter = 0
) => {
    const user_identifier = getUserIdentifier();
    return async dispatch => {
        try {
            const res = await API.checkCart(getSessionCartId(), localStorage.getItem(token_name));
            if (res.status === "Cart not found") {
                removeCart();
            }

            // Make API call to add product to the cart
            const data = await API.addToCart({ item, quantity: quantityCount, thelia_login: localStorage.getItem(token_name) });

            if (data.status_code === 200 && data.data.quantity) {
                if (addToast) {
                    addToast(translatedMessage, { appearance: "success", autoDismiss: true }); // Added To Cart
                }
                // If API call is successful, dispatch action to add product to the cart
                if (user_identifier) {
                    pushDataLayer({
                        event: "addToCart",
                        item_id: item.REF,
                        item_quantity: data.data.quantity,
                        item_name: item.TITLE,
                        item_brand: item.brand_name,
                        item_category: item.category[0],
                        item_price: item.BEST_TAXED_PRICE,
                        item_email: user_identifier
                    });
                } else {
                    pushDataLayer({
                        event: "addToCart",
                        item_id: item.REF,
                        item_quantity: data.data.quantity,
                        item_name: item.TITLE,
                        item_brand: item.brand_name,
                        item_category: item.category[0],
                        item_price: item.BEST_TAXED_PRICE,
                    });
                }
                dispatch({
                    type: ADD_TO_CART,
                    payload: {
                        ...item,
                        cartId: data.data.cartId ? data.data.cartId : getSessionCartId(),
                        cartItemId: data.data.cartItemId,
                        quantity: data.data.quantity,
                        selectedFulfilmentCenter,
                    }
                });
                setCartId(data.data.cartId ? data.data.cartId : getSessionCartId());

                return true;
            }
            if (addToast) {
                addToast("Hinzufügen zum Einkaufswagen fehlgeschlagen", {
                    appearance: "error",
                    autoDismiss: true
                }); // Adding to cart failed
            }
            return false;

        } catch (error) {
            // If API call fails, handle error
            console.error(error);
            return false;
        }
    };
};
// abandoned add to cart
export const addToCartAbandoned = (
    item,
    cartId,
    cartItemId,
    quantityCount,
    selectedProductColor,
    selectedProductSize,
    selectedFulfilmentCenter = 0
) => {
    const user_identifier = getUserIdentifier();
    return dispatch => {
        if (user_identifier) {
            pushDataLayer({
                event: "addToCart",
                item_id: item.REF,
                item_quantity: quantityCount,
                item_name: item.TITLE,
                item_brand: item.brand_name,
                item_category: item.category[0],
                item_price: item.BEST_TAXED_PRICE,
                item_email: user_identifier
            });
        } else {
            pushDataLayer({
                event: "addToCart",
                item_id: item.REF,
                item_quantity: quantityCount,
                item_name: item.TITLE,
                item_brand: item.brand_name,
                item_category: item.category[0],
                item_price: item.BEST_TAXED_PRICE,
            });
        }
        dispatch({
            type: ADD_TO_CART,
            payload: {
                ...item,
                cartId: cartId || getSessionCartId(),
                cartItemId,
                quantity: quantityCount,
                selectedFulfilmentCenter,
            }
        });
        setCartId(cartId || getSessionCartId());
    };
};
// decrease from cart
export const decreaseQuantity = (item, addToast, translatedMessage, quantityCount) => dispatch => {
    if (item.quantity - quantityCount < 1) {
        return API.removeFromCart({
            item,
            cart_id: item.cartId,
            thelia_login: localStorage.getItem(token_name),
        })
            .then((data) => {
                if (data.status_code === 200) {
                    if (addToast) {
                        addToast('Aus dem Einkaufswagen entfernt', { appearance: 'error', autoDismiss: true }); // Removed From Cart
                    }
                    item.quantityCount = quantityCount;
                    dispatch({ type: DELETE_FROM_CART, payload: item });
                    // removeCart();
                } else if (addToast) {
                    addToast('Hinzufügen zum Einkaufswagen fehlgeschlagen', {
                        appearance: 'error',
                        autoDismiss: true,
                    }); // Adding to cart failed
                }
            },
            ).catch(error => {
                // If API call fails, handle error
                console.error(error);
            });
    }
    return API.updateQuantity({
        item,
        thelia_login: localStorage.getItem(token_name),
        action: 'decrease',
        quantityCount,
    }).then((resp) => {
        if (resp.status_code === 200 && resp.data.quantity) {
            if (addToast) {
                addToast(translatedMessage, { // Item Decremented From Cart
                    appearance: 'warning',
                    autoDismiss: true,
                });
            }
            item.quantityRemain = resp.data.quantity;
            dispatch({ type: DECREASE_QUANTITY, payload: item });
        }
    })
        .catch(error => {
            // If API call fails, handle error
            console.error(error);
        });

};
// delete from cart
export const deleteFromCart = (item, addToast, translatedMessage) => dispatch =>
    // if (!fetchToken) {
    //     return false;
    // }
    API.removeFromCart({ item, cart_id: item.cartId, thelia_login: localStorage.getItem(token_name) })
        .then((data) => {
            if (data.status_code === 200) {
                if (addToast) {
                    addToast(translatedMessage, { appearance: "error", autoDismiss: true }); // Removed From Cart
                }

                dispatch({ type: DELETE_FROM_CART, payload: item });
                // removeCart();
            } else if (addToast) {
                addToast("Hinzufügen zum Einkaufswagen fehlgeschlagen", {
                    appearance: "error",
                    autoDismiss: true
                }); // Adding to cart failed
            }
        }
        ).catch(error => {
            // If API call fails, handle error
            console.error(error);
        })
    ;

// change fulfilment from cart
export const changeFulfilment = (cartItemId, selectedFulfilmentCenter, addToast, translatedMessage) => dispatch => {
    if (addToast) {
        addToast(translatedMessage, {
            appearance: "success",
            autoDismiss: true
        });
    }
    dispatch({
        type: CHANGE_FULFILMENT,
        payload: {
            cartItemId,
            selectedFulfilmentCenter,
        }
    });
};

// delete all from cart
export const deleteAllFromCart = (addToast, translatedMessage) => dispatch => {
    if (addToast) {
        addToast(translatedMessage, { appearance: "success", autoDismiss: true }); // Cart cleared
    }
    deleteSessionCart();
    dispatch({ type: DELETE_ALL_FROM_CART });
};

export const clearCart = () => dispatch => {
    dispatch({ type: DELETE_ALL_FROM_CART });
}

// get stock of cart item
export const cartItemStock = (item, color, size) => {

    if (item?.stock) {
        return item?.stock;
    }
    return item?.variation
        ?.filter(single => single.color === color)[0]
        .size.filter(single => single.name === size)[0].stock;


};

export const updateCartItem = (updatedItem) => {
    return {
        type: UPDATE_CART_ITEM,
        payload: updatedItem,  // Pass the updated item with the new 'undeliverable' property
    };
};

export function setCartId(cartId) {
    setSessionCartId(cartId);
    // TODO make it actually use Redux
    // return dispatch => {
    //     dispatch({type: SET_CART_ID, payload: cartId});  // note the change here
    // }
}
