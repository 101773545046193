import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import { useTranslation } from "react-i18next";

import TrustedShopsFooterWidget from "./TrustedShopsFooterWidget";
import FooterNewsletter from "../../components/footer/FooterNewsletter";

import useLocaleConfigSelectors from '../../helpers/useLocaleConfigSelectors.js';
import { useGetSystemAllQuery } from '../../redux/service/fastApiService.js';
import config_react from '../../config/config.js';
import { Helmet } from 'react-helmet';
import config_src from '../../config/config.js';



const FooterOne = ({
    footerLogo,
    mailPicture,
    backgroundColorClass,
    spaceTopClass,
    spaceBottomClass,
    spaceLeftClass,
    spaceRightClass,
    containerClass,
    extraFooterClass,
    sideMenu
}) => {
    const { t } = useTranslation();
    const [scroll, setScroll] = useState(0);
    const [top, setTop] = useState(0);
    const {
        getFooterImage1,
        getFooterImage2,
        getFooterImage3,
        getFooterImage4,
        getIoonsFacebookUrl,
        getIoonsInstagramUrl,
        getIoonsYoutubeUrl,
        getIoonsTikTokUrl,
        getLogoFooter: logo_footer,
        getSiteName: siteName,
        getLangCode: LangCode,
        getShopvoteDisplay: ShopvoteDisplay,
        getShopvoteShopId: ShopvoteShopId,
        getTrustedShopDisplay: TrustedShopDisplay,
        getSal: Sal,
        getAnpc: Anpc,
        getDisplayANPC
    } = useLocaleConfigSelectors();

    const LangParts = LangCode.split('_');
    const LangCodeF = LangParts[0];

    useEffect(() => {
        setTop(100);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        animateScroll.scrollToTop({
            smooth: true,
            duration: 500
        });
        //window.scrollTo(0, 0);
    };

    const handleScroll = () => {
        setScroll(window.scrollY);
    };
    const { data: { storePhone, storeSupportTime, storeAddress1, storeCity, storeZipcode, storeName } } = useGetSystemAllQuery({}, {
        refetchOnMountOrArgChange: config_src.refetchTimingLong,
        selectFromResult: (result) => ({
            ...result,
            data: {
                storePhone: result.data ? result.data['store_phone'] : "",
                storeSupportTime: result.data ? result.data['support_time'] : "",
                storeAddress1: result.data ? result.data['store_address1'] : "",
                storeCity: result.data ? result.data['store_city'] : "",
                storeZipcode: result.data ? result.data['store_zipcode'] : "",
                storeName: result.data ? result.data['store_name'] : "",
            }
        }),
    });
    const [locale, setLocale] = useState(null);
    useEffect(() => {
        const initialLocale = config_src.locale;
        if (initialLocale) {
            const parts = initialLocale.split("_");
            const secondPart = parts[1] ? parts[1].toLowerCase() : null;
            setLocale(secondPart);
        }
    }, []);

    return (
        <Fragment>
            <Helmet>
                {storePhone && storeAddress1 && storeCity && storeZipcode &&
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{
                            __html: JSON.stringify({
                                "@context": "https://schema.org",
                                "@type": "Store",
                                name: siteName,
                                image: logo_footer,
                                address: {
                                    "@type": "PostalAddress",
                                    streetAddress: storeAddress1,
                                    addressLocality: storeCity,
                                    postalCode: storeZipcode,
                                },
                                priceRange: '$',
                                telephone: storePhone
                            })
                        }}
                    />
                }

            </Helmet>
            <footer
                className={`footer-area ${backgroundColorClass ? backgroundColorClass : ""
                    } ${spaceTopClass ? spaceTopClass : ""} ${spaceBottomClass ? spaceBottomClass : ""
                    } ${extraFooterClass ? extraFooterClass : ""} ${spaceLeftClass ? spaceLeftClass : ""
                    } ${spaceRightClass ? spaceRightClass : ""}`}
            >
                <div className={`${containerClass ? containerClass : "container"}`}>
                    <div className="row">
                        <div
                            className="col-lg-12 col-sm-12">
                            <div className="footer-logo ml-30 mb-30">
                                <Link to={process.env.PUBLIC_URL + "/"}>
                                    <img alt="" src={process.env.PUBLIC_URL + footerLogo} />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-4">
                            <div className="footer-widget mb-30 ml-30">
                                <div className="footer-title">
                                    <h3>{t('Contact')}</h3>
                                </div>
                                <div className="footer-list">
                                    <ul>
                                        <li>
                                            {storeAddress1}<br />{locale ? `${locale.toUpperCase()} -` : null} {storeZipcode} {storeCity}
                                            {locale !== 'ro' ? (
                                                    <>
                                                        <br />
                                                        <span className="no-pickup">{t('No pickup possible!')}</span>
                                                    </>
                                                ) : null
                                            }
                                        </li>
                                        <li>
                                            {storePhone}
                                        </li>
                                        <li>
                                            <img className="footer-mail-img" alt="" src={process.env.PUBLIC_URL + mailPicture} />
                                        </li>
                                        <li>
                                            {storeSupportTime}
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-icons">
                                    <a
                                        href={getIoonsFacebookUrl}
                                        target="_blank"
                                        className="social-icon social-icon-facebook"
                                        rel="noopener noreferrer nofollow"
                                    >
                                        <svg className="contact-social-facebook" fill="#000000" width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M30.996 16.091c-0.001-8.281-6.714-14.994-14.996-14.994s-14.996 6.714-14.996 14.996c0 7.455 5.44 13.639 12.566 14.8l0.086 0.012v-10.478h-3.808v-4.336h3.808v-3.302c-0.019-0.167-0.029-0.361-0.029-0.557 0-2.923 2.37-5.293 5.293-5.293 0.141 0 0.281 0.006 0.42 0.016l-0.018-0.001c1.199 0.017 2.359 0.123 3.491 0.312l-0.134-0.019v3.69h-1.892c-0.086-0.012-0.185-0.019-0.285-0.019-1.197 0-2.168 0.97-2.168 2.168 0 0.068 0.003 0.135 0.009 0.202l-0.001-0.009v2.812h4.159l-0.665 4.336h-3.494v10.478c7.213-1.174 12.653-7.359 12.654-14.814v-0z"></path>
                                        </svg>
                                    </a>
                                    <a
                                        href={getIoonsInstagramUrl}
                                        target="_blank"
                                        className="social-icon social-icon-insta"
                                        rel="noopener noreferrer nofollow"
                                    >
                                        <svg className="contact-social-instagram" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                            <defs>
                                                <radialGradient id="instagramGradient" cx="30%" cy="107%" r="107%" fx="30%" fy="107%">
                                                    <stop offset="0%" style={{ stopColor: '#fdf497', stopOpacity: 1 }} />
                                                    <stop offset="5%" style={{ stopColor: '#fdf497', stopOpacity: 1 }} />
                                                    <stop offset="45%" style={{ stopColor: '#fd5949', stopOpacity: 1 }} />
                                                    <stop offset="60%" style={{ stopColor: '#d6249f', stopOpacity: 1 }} />
                                                    <stop offset="90%" style={{ stopColor: '#285AEB', stopOpacity: 1 }} />
                                                </radialGradient>
                                            </defs>
                                            <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z" className="instagram-icon" />
                                        </svg>
                                    </a>
                                    <a
                                        href={getIoonsYoutubeUrl}
                                        target="_blank"
                                        className="social-icon social-icon-youtube"
                                        rel="noopener noreferrer nofollow"
                                    >
                                        <svg className="contact-social-youtube" width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                                        </svg>
                                    </a>
                                    {getIoonsTikTokUrl && (
                                        <a
                                            href={getIoonsTikTokUrl}
                                            target="_blank"
                                            className="social-icon social-icon-tiktok"
                                            rel="noopener noreferrer nofollow"
                                        >
                                            <svg className="contact-social-tiktok" xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 1000 1000"><path d="M906.25 0H93.75C42.19 0 0 42.19 0 93.75v812.49c0 51.57 42.19 93.75 93.75 93.75l812.5.01c51.56 0 93.75-42.19 93.75-93.75V93.75C1000 42.19 957.81 0 906.25 0zM684.02 319.72c-32.42-21.13-55.81-54.96-63.11-94.38-1.57-8.51-2.45-17.28-2.45-26.25H515l-.17 414.65c-1.74 46.43-39.96 83.7-86.8 83.7-14.57 0-28.27-3.63-40.35-9.99-27.68-14.57-46.63-43.58-46.63-76.97 0-47.96 39.02-86.98 86.97-86.98 8.95 0 17.54 1.48 25.66 4.01V421.89c-8.41-1.15-16.95-1.86-25.66-1.86-105.01 0-190.43 85.43-190.43 190.45 0 64.42 32.18 121.44 81.3 155.92 30.93 21.72 68.57 34.51 109.14 34.51 105.01 0 190.43-85.43 190.43-190.43V400.21c40.58 29.12 90.3 46.28 143.95 46.28V343.03c-28.89 0-55.8-8.59-78.39-23.31z"/></svg>
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-sm-4">
                            <FooterNewsletter
                                spaceBottomClass="mb-30"
                                spaceLeftClass="ml-30"
                            />
                        </div>
                        <div
                            className="col-lg-3 col-sm-4">
                            <div className="footer-widget mb-30 ml-50">
                                <div className="footer-title">
                                    <h3>{t('Shopping & Support')}</h3>
                                </div>
                                <div className="footer-list">
                                    <ul>
                                        <li>
                                            <Link to={`${process.env.PUBLIC_URL}/${t('data-protection')}`}>{t('Data protection')}</Link>
                                        </li>
                                        <li>
                                            <Link to={`${process.env.PUBLIC_URL}/${t('terms-and-conditions')}`}>{t('Terms and conditions')}</Link>
                                        </li>
                                        <li>
                                            <Link to={`${process.env.PUBLIC_URL}/${t('right-of-withdrawal')}`}>{t('Right of withdrawal')}</Link>
                                        </li>
                                        <li>
                                            <Link to={`${process.env.PUBLIC_URL}/${t('faq')}`}>{t('Frequently Asked Questions (FAQ)')}</Link>
                                        </li>
                                        {getDisplayANPC !== '0' && getDisplayANPC !== 0 && getDisplayANPC !== null ? (
                                            <div className="anpc-sal">
                                                <li style={{ display: 'flex', gap: '8px' }}>
                                                    <a href="https://anpc.ro/ce-este-sal/" target="_blank" rel="noopener noreferrer nofollow">
                                                        <img style={{ width: "100%" }} alt="" src={process.env.PUBLIC_URL + Anpc} />
                                                    </a>
                                                </li>
                                                <li style={{ display: 'flex', gap: '8px' }}>
                                                    <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO" target="_blank" rel="noopener noreferrer nofollow">
                                                        <img style={{ width: "100%" }} alt="" src={process.env.PUBLIC_URL + Sal} />
                                                    </a>
                                                </li>
                                            </div>
                                        ) :
                                        ( 
                                            <li>
                                                <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE" target="_blank" rel="noopener noreferrer nofollow">
                                                    {t('Online dispute resolution')}
                                                </a>
                                            </li>
                                        )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-sm-4">
                            <div
                                className="footer-widget mb-30 ml-75">
                                <div className="footer-title">
                                    <h3>{t('About')} {storeName}</h3>
                                </div>
                                <div className="footer-list">
                                    <ul>
                                        <li>
                                            <Link to={`${process.env.PUBLIC_URL}/about`}>{t('About us')}</Link>
                                        </li>
                                        <li>
                                            <Link to={`${process.env.PUBLIC_URL}/${t('imprint')}`}>{t('Imprint')}</Link>
                                        </li>
                                        {locale !== 'ro' ? (
                                            <li>
                                                <Link to={`${process.env.PUBLIC_URL}/${t('press')}`}>{t('Press')}</Link>
                                            </li>
                                        ) : (
                                            <>
                                                <li>
                                                    <Link to={`${process.env.PUBLIC_URL}/conditii-de-garantie.html`}>{t('Conditii de garantie')}</Link>
                                                </li>
                                                <li>
                                                    <Link to={`${process.env.PUBLIC_URL}/manual-de-utilizare-1.html`}>{t('Manual de utilizare')}</Link>
                                                </li>
                                            </>
                                        )}
                                        <li>
                                            <Link to={`${process.env.PUBLIC_URL}/contact`}>{t('Contact')}</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-4">
                            <div className="footer-widget mb-30 ml-30">
                                <div className="footer-title">
                                    <h3>
                                        <Link to={{ pathname: `${process.env.PUBLIC_URL}/${t('pay')}`, }}>
                                            {t('Safe payment')}
                                        </Link>
                                    </h3>
                                </div>
                                <div className="footer-list">
                                    <ul>
                                        {getFooterImage1 &&
                                            <li>
                                                {/* <img
                                                className="footer-payments-img"
                                                alt=""
                                                src={process.env.PUBLIC_URL + getFooterImage1}
                                                onClick={() => {
                                                    window.location.href = `${process.env.PUBLIC_URL}/${t('pay')}`;
                                                }}
                                            /> */}

                                                <Link
                                                    to={{
                                                        pathname: `${process.env.PUBLIC_URL}/${t('pay')}`,
                                                    }}
                                                >
                                                    <img alt="" src={process.env.PUBLIC_URL + getFooterImage1} />
                                                </Link>
                                            </li>
                                        }
                                        {getFooterImage4 &&
                                            <li>
                                                <Link
                                                    to={{
                                                        pathname: `${process.env.PUBLIC_URL}/${t('pay')}`,
                                                    }}
                                                >
                                                    <img alt="" src={process.env.PUBLIC_URL + getFooterImage4} />
                                                </Link>
                                            </li>
                                        }
                                        {getFooterImage3 &&
                                            <li>
                                                <Link
                                                    to={{
                                                        pathname: `${process.env.PUBLIC_URL}/${t('pay')}`,
                                                    }}
                                                >
                                                    <img alt="" src={process.env.PUBLIC_URL + getFooterImage3} />
                                                </Link>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-sm-4">
                            <div className="footer-widget mb-30 ml-30">
                                <div className="footer-title">
                                    <h3>
                                        <Link to={{ pathname: `${process.env.PUBLIC_URL}/${t('delivery')}`, }}>
                                            {t('Reliable delivery')}
                                        </Link>
                                    </h3>
                                </div>
                                <div className="footer-list">
                                    <ul>
                                        <li>
                                            <Link
                                                to={{
                                                    pathname: `${process.env.PUBLIC_URL}/${t('delivery')}`,
                                                }}
                                            >
                                                <img alt="" src={process.env.PUBLIC_URL + getFooterImage2} />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-sm-4">
                            <div
                                className="footer-widget mb-30 ml-50">
                                {TrustedShopDisplay.footer === true ? (
                                    <>
                                        <div className="footer-title">
                                            <h3>{t('Trusted Shops')}</h3>
                                        </div>
                                        <TrustedShopsFooterWidget />
                                    </>
                                ) : ShopvoteDisplay.footer === true ? (
                                    <>
                                        <div className="footer-title">
                                            <h3>
                                                <a href={`https://www.shopvote.de/bewertung_ioons_de_${ShopvoteShopId}.html`}>
                                                    {t('ShopVote')}
                                                </a>
                                            </h3>
                                        </div>
                                        <a href={`https://www.shopvote.de/bewertung_ioons_de_${ShopvoteShopId}.html`} target="_blank">
                                            <img src={`https://widgets.shopvote.de/view.php?shopid=${ShopvoteShopId}&bn=51&lang=${LangCodeF}`} id="svimage-51" alt={t('Shop rating') + ' - ioons.de'} border="0" align="bottom" hspace="1" />
                                        </a>
                                    </>
                                ) : null}
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-sm-4">
                            <div
                                className="footer-widget mb-30 ml-75">
                                {ShopvoteDisplay.footer === true && TrustedShopDisplay.footer === true && (
                                    <>
                                        <div className="footer-title">
                                            <h3>{t('ShopVote')}</h3>
                                        </div>
                                        <div className="footer-list">
                                            <a href={`https://www.shopvote.de/bewertung_ioons_de_${ShopvoteShopId}.html`} target="_blank">
                                                <img src={`https://widgets.shopvote.de/view.php?shopid=${ShopvoteShopId}&bn=51&lang=${LangCodeF}`} id="svimage-51" alt={t('Shop rating') + ' - ioons.de'} border="0" align="bottom" hspace="1" />
                                            </a>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                        <div
                            className="col-lg-12 col-sm-12">
                            <div className="footer-logo ml-30">
                                &copy; {storeName} {new Date().getFullYear()}{" "}
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    className={`scroll-top ${scroll > top ? "show" : ""}${ShopvoteDisplay.badge === true && TrustedShopDisplay.badge === true ? " scroll-top-marg" : ""}`}
                    onClick={() => scrollToTop()}
                >
                    <i className="fa fa-angle-double-up"></i>
                </button>
            </footer>
        </Fragment>
    );
};

FooterOne.propTypes = {
    backgroundColorClass: PropTypes.string,
    containerClass: PropTypes.string,
    extraFooterClass: PropTypes.string,
    sideMenu: PropTypes.bool,
    spaceBottomClass: PropTypes.string,
    spaceTopClass: PropTypes.string,
    spaceLeftClass: PropTypes.string,
    spaceRightClass: PropTypes.string
};

export default FooterOne;
